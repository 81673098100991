import { FC, SyntheticEvent, useEffect, useState } from "react";

import { Box, Breadcrumbs, Stack, Typography } from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import { StyledTab, StyledTabs } from "components/StyledTab/StyledTab";
import {
  useAppDispatch,
  useAppSelector,
  useAppThunkDispatch,
} from "../../store";
import { getClientThunk } from "../../store/features/thunk/clientThunk/clientThunk";
import { ClientPdfDocumentComponent } from "components/ClientPdfDocument/ClientPdfDocumentComponent";
import { clearClient } from "store/features/client/clientSlice";

export const ClientComponent: FC = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const [value, setValue] = useState(0);
  const [breadcrumbs] = useState(true);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const dispatchThunk = useAppThunkDispatch();
  const { clientDocument } = useAppSelector(({ client }) => client);

  useEffect(() => {
    if (clientId) {
      dispatchThunk(getClientThunk(`${clientId}`));
    } else {
      dispatch(clearClient());
    }
  }, [clientId, dispatchThunk]);

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      {clientDocument && clientDocument.userUuid === clientId && (
        <ClientPdfDocumentComponent clientDocument={clientDocument} />
      )}
      <Box sx={{ padding: "0 50px 0 305px" }}>
        {breadcrumbs && (
          <Box
            sx={{
              display: "flex",
              borderBottom: 1,
              borderColor: "divider",
              justifyContent: "space-between",
            }}
          >
            <Stack spacing={2}>
              <Breadcrumbs
                separator={<NavigateNext fontSize="small" color={"success"} />}
                aria-label="breadcrumb"
              >
                <Typography
                  key="1"
                  color="text.primary"
                  sx={{ padding: "14px 0" }}
                >
                  Clients
                </Typography>
                <Typography
                  key="2"
                  color="text.primary"
                  sx={{ padding: "14px 0" }}
                >
                  Client info
                </Typography>
              </Breadcrumbs>
            </Stack>
          </Box>
        )}
        <Box maxHeight={"850px"} overflow={"auto"}>
          <StyledTabs
            value={value}
            onChange={(e, newValue) => clientId && handleChange(e, newValue)}
            aria-label="basic tabs example"
            sx={{
              marginBottom: "20px",
            }}
          >
            <StyledTab
              label="Profile Info"
              {...a11yProps(0)}
              onClick={() => navigate("info")}
            />
            <StyledTab
              label="All documents"
              {...a11yProps(1)}
              onClick={() => clientId && navigate("documents")}
            />
          </StyledTabs>
          <Outlet />
        </Box>
      </Box>
    </>
  );
};
