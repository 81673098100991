import {
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import slug from "slug";
import { useNavigate, useParams } from "react-router-dom";
import { FieldValues, FormProvider, useForm } from "react-hook-form";

import { setNewWorkflow } from "store/features/workflow/workflowSlice";
import { ContentCopy, NavigateNext, OpenInNew } from "@mui/icons-material";
import {
  StyledTab,
  StyledTabs,
  TabPanel,
} from "components/StyledTab/StyledTab";
import { WebhookExtension } from "components/WorkflowExtensions/WebhookExtension";
import {
  AppearanceExtension,
  NotificationExtension,
} from "components/WorkflowExtensions";
import {
  Box,
  Breadcrumbs,
  Button,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";

import { Loader } from "../Loader/Loader";
import { TITLE_ALREADY_TAKEN } from "./WorkflowConstants";
import { InputWidget } from "../widgets/FormWidgets/InputWidget";
import { SelectWidget } from "../widgets/FormWidgets/SelectWidget";
import { copyTextToClipboard, openNewTab } from "../../helper/urlHelper";
import { DropboxExtension } from "../WorkflowExtensions/DropboxExtension";
import { DomainInputWidget } from "../widgets/FormWidgets/DomainInputWidget";
import WorkflowEditJsonData from "../WorkflowEditJsonData/WorkflowEditJsonData";
import {
  useAppDispatch,
  useAppSelector,
  useAppThunkDispatch,
} from "../../store";
import { getLocationsThunk } from "../../store/features/thunk/locationThunk/locationThunk";
import { getDocumentsThunk } from "../../store/features/thunk/documentThunk/documentThunk";
import { useTemplateWorkflowMutation } from "../../store/features/api/workflowApi/workflowApi";
import { WorkflowDocumentsSelector } from "../WorkflowDocumentsSelector/WorkflowDocumentsSelector";
import {
  createWorkflowThunk,
  getWorkflowThunk,
  updateWorkflowThunk,
} from "../../store/features/thunk/workflowThunk/workflowThunk";
import { WebhookExtensionWrapper } from "components/WorkflowExtensions/WebhookExtensionWrapper";

const transformTemplateData = (
  data: { [s: string]: unknown } | ArrayLike<unknown>,
): { label: unknown; value: string }[] => {
  return Object.entries(data).map(([value, label]) => ({
    value,
    label,
  }));
};

export const WorkflowComponent: FC = () => {
  const [value, setValue] = useState(0);
  const [optionList, setOptionList] = useState<
    { label: string; value: string }[] | []
  >([]);
  const [options, setOptions] = useState<never[]>([]);

  const titleRef = useRef<HTMLInputElement>(null);

  const { locations } = useAppSelector(({ location }) => location);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { id } = useParams<{ id: string }>();

  const formMethods = useForm();
  const dispatchThunk = useAppThunkDispatch();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { documents } = useAppSelector(({ document }) => document);
  const domain = localStorage.getItem("BASE_URL_DOMAIN") ?? "";

  const { handleSubmit, watch, setValue: setFieldValue } = formMethods;
  const watchWorkflowTitle = watch("title");

  useEffect(() => {
    const optionList = locations.length
      ? [
          ...locations.map((location) => ({
            label: location.name,
            value: `${location.id}`,
          })),
        ]
      : [];

    setOptionList(optionList);
  }, [locations]);

  useEffect(() => {
    initial();

    return () => {
      dispatch(setNewWorkflow(null));
    };
  }, []);

  const [getTemplate, { data: template }] = useTemplateWorkflowMutation();

  useEffect(() => {
    if (template) {
      const transformedData = transformTemplateData(template);
      setOptions(transformedData as never[]);
    }
  }, [template]);

  let data = useAppSelector(({ workflow }) => workflow.editWorkflow);
  // useEffect(() => {
  //   if (id && id !== "new") {
  //     try {
  //       dispatchThunk(getWorkflowThunk(id));
  //     } catch (error: any) {
  //       setIsError(true);
  //     }
  //   }
  // }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      if (id && id !== "new") {
        try {
          setIsLoading(true);
          await dispatchThunk(getWorkflowThunk(id));
        } catch (error) {
          setIsError(true);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    if (!data) return;
    setFieldValue("title", data.title);
    setFieldValue("slug", data.slug || watchWorkflowTitle);
    setFieldValue("locationId", data.locationId);
  }, [data]);

  useEffect(() => {
    setFieldValue(
      "slug",
      typeof watchWorkflowTitle === "undefined"
        ? ""
        : slug(watchWorkflowTitle, "_"),
    );
  }, [watchWorkflowTitle, setFieldValue]);

  const [isShowJson, setIsShowJson] = useState(false);

  const handleKeyPress = useCallback((event: KeyboardEvent) => {
    if (event.ctrlKey && event.shiftKey && event.key.toUpperCase() === "K") {
      setIsShowJson((prevState) => !prevState);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  const handleWorkflowUpdateJSON = (jsonData: any) => {
    if (!data) return;
    dispatchThunk(
      updateWorkflowThunk({
        data: {
          ...jsonData,
        },
        id: data?.id,
        path: "",
      }),
    );
  };

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  const initial = async () => {
    await dispatchThunk(getLocationsThunk());
    await dispatchThunk(getDocumentsThunk());
    await getTemplate({});
  };

  const onCreateWorkflow = async (formData: FieldValues) => {
    if (data) return;
    try {
      setIsLoading(true);
      const res = await dispatchThunk(
        createWorkflowThunk({
          title: formData.title,
          slug: formData.slug,
          locationId: formData.locationId,
          template: formData.templateId,
        }),
      );

      if (res.payload.data?.message === TITLE_ALREADY_TAKEN) {
        if (titleRef.current) titleRef.current.focus();
        return;
      }

      navigate(`/workflow/${res.payload}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleWorkflowUpdate = (formData: FieldValues) => {
    if (!data) return;
    dispatchThunk(
      updateWorkflowThunk({
        data: {
          title: formData.title,
          slug: formData.slug,
          locationId: formData.locationId,
        },
        id: data?.id,
        path: "",
      }),
    );
  };

  const handleChange = async (_event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      {isLoading && <Loader isLoading={isLoading} />}
      {/* eslint-disable-next-line react/jsx-no-undef */}
      <>
        <Box sx={{ padding: "0 50px 0 305px" }}>
          <Box
            sx={{
              display: "flex",
              borderBottom: 1,
              borderColor: "divider",
              justifyContent: "space-between",
            }}
          >
            <Stack spacing={2}>
              <Breadcrumbs
                separator={<NavigateNext fontSize="small" color={"success"} />}
                aria-label="breadcrumb"
              >
                <Typography
                  key="1"
                  color="text.primary"
                  sx={{ padding: "14px 0" }}
                >
                  Workflows
                </Typography>
                <Typography
                  key="2"
                  color="text.primary"
                  sx={{ padding: "14px 0" }}
                >
                  {id === "new" ? "New Workflow" : "Edit Workflow"}
                </Typography>
              </Breadcrumbs>
            </Stack>
          </Box>
          {isError && "Error"}
          {data && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                marginTop: "15px",
              }}
            >
              <Button
                variant={"contained"}
                color={"success"}
                disabled={
                  !Object.keys(data?.extensions?.documents ?? {}).length
                }
                onClick={() => {
                  openNewTab(`https://${domain}/form/${data?.slug}`);
                }}
                sx={{
                  "&:disabled": {
                    backgroundColor: "#2E7D32",
                    color: "white",
                    opacity: "0.5",
                  },
                }}
              >
                Test Workflow
              </Button>
            </Box>
          )}
          <FormProvider {...formMethods}>
            <form
              onSubmit={handleSubmit(onCreateWorkflow)}
              onBlur={handleSubmit(handleWorkflowUpdate)}
              noValidate
            >
              <Box width={"100%"} gap={"25px"}>
                <div style={{ width: "100%" }}>
                  <InputWidget
                    fieldId={"title"}
                    label={"Workflow Title"}
                    required={true}
                    type={"text"}
                    value={data?.title || "NEW Workflow"}
                    inputRef={titleRef}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <DomainInputWidget
                    fieldId={"slug"}
                    required={true}
                    label={"Workflow Url"}
                    type={"text"}
                    value={data?.slug || ""}
                    InputProps={{
                      inputProps: {
                        style: { width: "unset", flexGrow: 1 },
                      },
                      startAdornment: (
                        <div
                          style={{
                            paddingRight: "0",
                            width: "fit-content",
                            color: "grey",
                            opacity: "0.5",
                          }}
                        >
                          {`https://${domain}/form/`}
                        </div>
                      ),
                      endAdornment: (
                        <Box display={"flex"} justifyContent={"space-between"}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <IconButton
                              aria-label="delete"
                              size="small"
                              onClick={() =>
                                copyTextToClipboard(
                                  `https://${domain}/form/${data?.slug}`,
                                )
                              }
                            >
                              <ContentCopy fontSize="inherit" />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              size="small"
                              onClick={() =>
                                openNewTab(
                                  `https://${domain}/form/${data?.slug}`,
                                )
                              }
                            >
                              <OpenInNew fontSize="inherit" />
                            </IconButton>
                          </Stack>
                        </Box>
                      ),
                    }}
                  />
                </div>
                <div className="w-full">
                  <SelectWidget
                    label={"Location"}
                    required={true}
                    value={data?.locationId || ""}
                    fieldId={"locationId"}
                    type={"select"}
                    option_list={optionList}
                  />
                </div>
                {id === "new" && (
                  <div className="w-full">
                    {template && (
                      <SelectWidget
                        label="Template"
                        fieldId="templateId"
                        type={"select"}
                        option_list={options}
                        required={false}
                        value=""
                      />
                    )}
                  </div>
                )}

                {!data && (
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      variant="contained"
                      fullWidth={false}
                      color="success"
                      type="submit"
                      style={{ marginTop: "20px" }}
                    >
                      Create Workflow
                    </Button>
                  </Box>
                )}
              </Box>
            </form>
          </FormProvider>
          {data && (
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <StyledTabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <StyledTab label="Documents" {...a11yProps(0)} />
                  <StyledTab label="Appearance" {...a11yProps(1)} />
                  <StyledTab label="Notifications" {...a11yProps(2)} />
                  <StyledTab label="Dropbox" {...a11yProps(3)} />
                  <StyledTab label="Webhooks" {...a11yProps(4)} />
                </StyledTabs>
              </Box>
              <TabPanel value={value} index={0}>
                <WorkflowDocumentsSelector
                  documents={documents}
                  workflowDocuments={data.extensions?.documents}
                  workflowId={data.id}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <AppearanceExtension workflowId={data.id} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <NotificationExtension workflowId={data.id} />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <DropboxExtension workflowId={data.id} />
              </TabPanel>
              <TabPanel value={value} index={4}>
                <WebhookExtensionWrapper workflowId={data.id} />
              </TabPanel>
            </Box>
          )}
          {data && isShowJson && (
            <WorkflowEditJsonData
              data={data}
              update={handleWorkflowUpdateJSON}
            />
          )}
        </Box>
      </>
    </>
  );
};
