import { FC, useEffect, useRef, useState } from "react";

import { useAppThunkDispatch } from "store";
import { FormProvider, useForm } from "react-hook-form";

import { Box, Button, FormGroup, Typography } from "@mui/material";
import { InputWidget } from "components/widgets/FormWidgets/InputWidget";
import { CustomSwitch } from "components/CustomSwitch/CustomSwitch";
import CheckIcon from "@mui/icons-material/Check";
import { CheckboxWidget } from "components/widgets/FormWidgets/CheckboxWidget";
import { SelectWidget } from "components/widgets/FormWidgets/SelectWidget";
import { updateWorkflowThunk } from "store/features/thunk/workflowThunk/workflowThunk";

export type WebhookExtensionProps = {
  data: any;
  workflowId: string;
};

export const WebhookExtension: FC<WebhookExtensionProps> = ({
  data,
  workflowId,
}) => {
  const [webhookSwitch, setWebhookSwitch] = useState(false);

  const dispatchThunk = useAppThunkDispatch();

  const webhookIntegration = data?.extensions?.webhooks?.enabled;

  const values = {
    webhookUrl: data.extensions.webhooks?.webhookUrl,
    documentSigned:
      data.extensions.webhooks?.eventTypes?.includes("document.signed"),
    profileUpdated:
      data.extensions.webhooks?.eventTypes?.includes("profile.updated"),
    profileCreated:
      data.extensions.webhooks?.eventTypes?.includes("profile.created"),
    httpMethod: data.extensions.webhooks?.httpMethod || "POST",
    secretToken: data.extensions.webhooks?.secretToken || generateRandomToken(),
  };

  const httpMethods = [
    { label: "POST", value: "POST" },
    { label: "PUT", value: "PUT" },
    { label: "GET", value: "GET" },
  ];

  const formMethods = useForm({
    defaultValues: {
      ...values,
    },
  });

  const { watch } = formMethods;

  const [documentSigned, profileUpdated, profileCreated, httpMethod] = watch([
    "documentSigned",
    "profileUpdated",
    "profileCreated",
    "httpMethod",
  ]);

  useEffect(() => {
    setWebhookSwitch(webhookIntegration);

    if (
      !data.extensions.webhooks?.httpMethod ||
      !data.extensions.webhooks?.secretToken
    ) {
      dispatchThunk(
        updateWorkflowThunk({
          data: {
            httpMethod,
            secretToken: values.secretToken,
          },
          id: workflowId || "",
          path: "/extension/webhooks",
        }),
      );
    }
  }, []);

  const isInitialEventTypesMount = useRef(true);
  const isInitialHttpMethodMount = useRef(true);

  useEffect(() => {
    if (isInitialEventTypesMount.current) {
      isInitialEventTypesMount.current = false;
      return;
    }

    dispatchThunk(
      updateWorkflowThunk({
        data: {
          eventTypes: [
            documentSigned && "document.signed",
            profileUpdated && "profile.updated",
            profileCreated && "profile.created",
          ].filter(Boolean),
        },
        id: workflowId || "",
        path: "/extension/webhooks",
      }),
    );
  }, [documentSigned, profileUpdated, profileCreated]);

  useEffect(() => {
    if (isInitialHttpMethodMount.current) {
      isInitialHttpMethodMount.current = false;
      return;
    }

    dispatchThunk(
      updateWorkflowThunk({
        data: {
          httpMethod,
        },
        id: workflowId || "",
        path: "/extension/webhooks",
      }),
    );
  }, [httpMethod]);

  const handleSwitchChange = (checked: boolean) => {
    setWebhookSwitch(checked);

    dispatchThunk(
      updateWorkflowThunk({
        data: {
          enabled: checked,
        },
        id: workflowId || "",
        path: "/extension/webhooks",
      }),
    );
  };

  const handleWebhookUrlSubmit = () => {
    const webhookUrl = formMethods.getValues("webhookUrl");

    dispatchThunk(
      updateWorkflowThunk({
        data: {
          webhookUrl,
        },
        id: workflowId || "",
        path: "/extension/webhooks",
      }),
    );
  };

  const handleSecretTokenSubmit = () => {
    const secretToken = formMethods.getValues("secretToken");

    dispatchThunk(
      updateWorkflowThunk({
        data: {
          secretToken,
        },
        id: workflowId || "",
        path: "/extension/webhooks",
      }),
    );
  };

  return (
    <FormProvider {...formMethods}>
      <form noValidate>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            minHeight: "85px",
            padding: "20px",
            marginBottom: "1px",
            borderRadius: "4px",
            backgroundColor: "#fff",
            boxShadow: "5px 5px 15px #d9d9d9, -5px -5px 15px #ffffff",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              width: "70%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography variant={"subtitle1"}>
                Seamlessly Receive Webhooks from Workflows
              </Typography>
              <Box sx={{ display: "flex", gap: "5px" }}>
                <Typography
                  variant={"body2"}
                  sx={{ opacity: "0.5", textAlign: "start" }}
                >
                  Enable this switch to activate webhook functionality within
                  your project. Once enabled, your workflows can trigger
                  webhooks to external systems, streamlining integrations and
                  enhancing automation.
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "20%",
            }}
          >
            <CustomSwitch
              name="enabled"
              onChange={(e) => handleSwitchChange(e.target.checked)}
              sx={{ margin: "auto" }}
              checked={webhookSwitch}
            />
          </Box>
        </Box>

        <Box sx={{ marginTop: "20px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              minHeight: "85px",
              padding: "20px",
              marginBottom: "1px",
              borderRadius: "4px",
              backgroundColor: "#fff",
              boxShadow: "5px 5px 15px #d9d9d9, -5px -5px 15px #ffffff",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                width: "70%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography variant={"subtitle1"}>
                  Configure Your Webhook URL
                </Typography>
                <Box sx={{ display: "flex", gap: "5px" }}>
                  <Typography
                    variant={"body2"}
                    sx={{ opacity: "0.5", textAlign: "start" }}
                  >
                    Specify the URL where your workflows will send webhook
                    requests. This setup ensures smooth communication between
                    your project and external services. Click the confirmation
                    button after entering the URL.
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <InputWidget
                fieldId="webhookUrl"
                label="Webhook URL"
                type="text"
                required={true}
              />
              <Button
                variant="contained"
                color="success"
                sx={{ marginTop: "8px" }}
                onClick={handleWebhookUrlSubmit}
              >
                <CheckIcon />
              </Button>
            </Box>
          </Box>
        </Box>

        <Box sx={{ marginTop: "20px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              minHeight: "85px",
              padding: "20px",
              marginBottom: "1px",
              borderRadius: "4px",
              backgroundColor: "#fff",
              boxShadow: "5px 5px 15px #d9d9d9, -5px -5px 15px #ffffff",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                width: "70%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography variant={"subtitle1"}>
                  Select Events to Trigger Webhooks
                </Typography>
                <Box sx={{ display: "flex", gap: "5px" }}>
                  <Typography
                    variant={"body2"}
                    sx={{ opacity: "0.5", textAlign: "start" }}
                  >
                    Choose the events that should trigger webhooks for your
                    workflows. Whether it's document signing, profile updates,
                    or new profile creation, select the ones most relevant to
                    your integration needs.
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <FormGroup>
                <CheckboxWidget
                  type="checkbox"
                  fieldId="documentSigned"
                  label="Document Signed"
                />
                <CheckboxWidget
                  type="checkbox"
                  fieldId="profileUpdated"
                  label="Profile Updated"
                />
                <CheckboxWidget
                  type="checkbox"
                  fieldId="profileCreated"
                  label="Profile Created"
                />
              </FormGroup>
            </Box>
          </Box>
        </Box>

        <Box sx={{ marginTop: "20px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              minHeight: "85px",
              padding: "20px",
              marginBottom: "1px",
              borderRadius: "4px",
              backgroundColor: "#fff",
              boxShadow: "5px 5px 15px #d9d9d9, -5px -5px 15px #ffffff",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                width: "70%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography variant={"subtitle1"}>
                  Specify the HTTP Method for Webhook Requests
                </Typography>
                <Box sx={{ display: "flex", gap: "5px" }}>
                  <Typography
                    variant={"body2"}
                    sx={{ opacity: "0.5", textAlign: "start" }}
                  >
                    Define how data should be sent in webhook requests by
                    selecting the appropriate HTTP method (e.g., POST, PUT,
                    GET). Choose based on your endpoint's requirements for
                    seamless compatibility.
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <SelectWidget
                type="select"
                fieldId="httpMethod"
                label="HTTP Method"
                option_list={httpMethods}
              />
            </Box>
          </Box>
        </Box>

        <Box sx={{ marginTop: "20px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              minHeight: "85px",
              padding: "20px",
              marginBottom: "1px",
              borderRadius: "4px",
              backgroundColor: "#fff",
              boxShadow: "5px 5px 15px #d9d9d9, -5px -5px 15px #ffffff",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                width: "70%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography variant={"subtitle1"}>
                  Secure Your Webhook Requests with a Secret Token
                </Typography>
                <Box sx={{ display: "flex", gap: "5px" }}>
                  <Typography
                    variant={"body2"}
                    sx={{ opacity: "0.5", textAlign: "start" }}
                  >
                    Add a secret token to authenticate webhook requests and
                    safeguard your workflows against unauthorized access. Click
                    the confirmation button to securely save your token.
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <InputWidget
                fieldId="secretToken"
                label="Secret token"
                type="text"
                required={true}
              />
              <Button
                variant="contained"
                color="success"
                sx={{ marginTop: "8px" }}
                onClick={handleSecretTokenSubmit}
              >
                <CheckIcon />
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
};

const generateRandomToken = () => {
  return Math.random().toString(36).slice(2, 12);
};
