import { FC, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";

import { Box, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { InputWidget } from "components/widgets/FormWidgets/InputWidget";
import { PhoneWidget } from "components/widgets/FormWidgets/PhoneWidget";
import { SelectWidget } from "components/widgets/FormWidgets/SelectWidget";

import { Loader } from "../../Loader/Loader";
import { useAppSelector, useAppThunkDispatch } from "../../../store";
import { TableComponent } from "../../TableComponent/TableComponent";
import { getClientsThunk } from "../../../store/features/thunk/clientThunk/clientThunk";

const headers: string[] = [
  "FIRST NAME",
  "LAST NAME",
  "CREATION DATE",
  "RECENT LOCATION",
  "DOCUMENTS COUNT",
  "RECENT DOCUMENTS",
];

export const Clients: FC<{ breadcrumbs?: boolean }> = () => {
  const [clientsFilter, setClientsFilter] = useState<Record<
    string,
    any
  > | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);

  const { clients } = useAppSelector(({ client }) => client);

  const navigate = useNavigate();
  const dispatchThunk = useAppThunkDispatch();

  const methods = useForm({
    defaultValues: {
      filterType: "any",
    },
  });
  const { handleSubmit, watch } = methods;

  const filterOptions = [
    { label: "Any fields", value: "any" },
    { label: "Specific fields", value: "specific" },
  ];
  const currentFilterType = watch("filterType");

  useEffect(() => {
    handleClients();
  }, [clientsFilter]);

  const handleClients = async () => {
    setIsLoading(true);
    await dispatchThunk(getClientsThunk({ clientsFilter, page, perPage }));
    setIsLoading(false);
  };

  const onSubmit = (data: Record<string, any>) => {
    const { filterType, query, ...restFields } = data;

    let filter;
    if (filterType === "any") {
      filter = { query };
    } else {
      filter = restFields;
    }

    setClientsFilter({ filter });
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "1rem",
          paddingBottom: "30px",
        }}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            style={{ width: "100%" }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(5, 1fr)",
                gap: "1rem",
              }}
            >
              <SelectWidget
                label={"Filter type"}
                fieldId={"filterType"}
                type={"select"}
                option_list={filterOptions}
              />
              {currentFilterType === "any" ? (
                <div style={{ gridArea: "2 / 1 / 2 / 5" }}>
                  <InputWidget
                    type={"text"}
                    fieldId={"query"}
                    label={"Search by name, phone, or email"}
                    value={""}
                  />
                </div>
              ) : (
                <>
                  <div style={{ gridArea: "2 / 1 / 2 / 2" }}>
                    <InputWidget
                      type={"text"}
                      fieldId={"firstName"}
                      label={"First name"}
                      value={""}
                    />
                  </div>
                  <div style={{ gridArea: "2 / 2 / 2 / 3" }}>
                    <InputWidget
                      type={"text"}
                      fieldId={"lastName"}
                      label={"Last name"}
                      value={""}
                    />
                  </div>
                  <div style={{ gridArea: "2 / 3 / 2 / 4" }}>
                    <InputWidget
                      type={"email"}
                      fieldId={"email"}
                      label={"Email"}
                      value={""}
                    />
                  </div>
                  <div style={{ gridArea: "2 / 4 / 2 / 5" }}>
                    <PhoneWidget
                      type={"phone"}
                      fieldId={"phone"}
                      label={"Phone"}
                      value={""}
                      rules={{}}
                    />
                  </div>
                </>
              )}
              <Button
                type={"submit"}
                variant={"contained"}
                color={"success"}
                sx={{
                  alignSelf: "center",
                  justifySelf: "start",
                  marginTop: "0.5rem",
                  gridArea: "2 / 5 / 2 / 6",
                }}
              >
                <SearchIcon />
              </Button>
            </div>
          </form>
        </FormProvider>
        <Box sx={{ marginTop: "1rem", alignSelf: "start", width: "12rem" }}>
          <Button
            variant={"contained"}
            color={"success"}
            onClick={() => {
              navigate("/client/newClient/info", { replace: true });
            }}
          >
            + Add new client
          </Button>
        </Box>
      </Box>
      <TableComponent
        type={"clients"}
        headers={headers}
        isEditDelete={false}
        rows={clients}
        onRowClick={(uuid) => {
          navigate(`/client/${uuid}/info`, { replace: true });
        }}
      />
    </>
  );
};
