import { FC, useEffect } from "react";
import { WebhookExtension } from "./WebhookExtension";
import { useGetWorkflowMutation } from "store/features/api/workflowApi/workflowApi";
import { Skeleton } from "@mui/material";

export type WebhookExtensionWrapperProps = {
  workflowId: string;
};

export const WebhookExtensionWrapper: FC<WebhookExtensionWrapperProps> = ({
  workflowId,
}) => {
  const [getWorkflow, { data, isLoading }] = useGetWorkflowMutation();

  useEffect(() => {
    getWorkflow({ id: workflowId });
  }, []);

  return isLoading ? (
    <Skeleton variant="rounded" width="100%" height={90} />
  ) : data ? (
    <WebhookExtension data={data} workflowId={workflowId} />
  ) : null;
};
