import { FieldValues } from "react-hook-form";

import { createApi } from "@reduxjs/toolkit/query/react";

import { OtpMethod } from "../../form/formSlice";
import { baseCustomFetchBase } from "../service/customFetchBase";

const clients = "clients";
const client = "client";

const clientApi = createApi({
  reducerPath: "clientApi",
  baseQuery: baseCustomFetchBase,
  endpoints: (builder) => ({
    signInWithSubdomain: builder.mutation<
      any,
      {
        email: string;
        password: string;
        verificationCodes: Record<any, any>;
        verificationType?: OtpMethod["type"];
      }
    >({
      query: (data) => ({
        url: `signin`,
        method: "POST",
        body: data,
      }),
    }),
    sendCode: builder.mutation<
      any,
      {
        verificationType: OtpMethod["type"];
        email?: string;
        phone_number?: any;
        secret?: string;
      }
    >({
      query: (data) => ({
        url: `2fa/send-code`,
        method: "POST",
        body: data,
      }),
    }),
    verifyCode: builder.mutation<
      any,
      {
        verificationCodes: Record<any, any>;
        verificationType?: OtpMethod["type"];
        email?: string;
        phone_number?: any;
        secret?: string;
      }
    >({
      query: (data) => ({
        url: `2fa/verify-code`,
        method: "POST",
        body: data,
      }),
    }),
    getAuthInfo: builder.mutation<any, any>({
      query: () => ({
        url: `/2fa/info`,
        method: "GET",
      }),
    }),
    deleteAuth: builder.mutation<
      any,
      {
        verificationType?: OtpMethod["type"];
      }
    >({
      query: (data) => ({
        url: `2fa`,
        method: "DELETE",
        body: data,
      }),
    }),
    changePassword: builder.mutation<
      any,
      {
        email: string;
      }
    >({
      query: (data) => ({
        url: `change-password`,
        method: "POST",
        body: data,
      }),
    }),
    changePasswordConfirm: builder.mutation<
      any,
      {
        verificationCodes: Record<any, any>;
        email: string;
        password: string;
        passwordConfirm: string;
      }
    >({
      query: (data) => ({
        url: `change-password/confirm`,
        method: "POST",
        body: data,
      }),
    }),
    freezeAccount: builder.mutation<any, any>({
      query: () => ({
        url: `/emergency/freeze/request`,
        method: "POST",
      }),
    }),
    unfreezeAccount: builder.mutation<any, any>({
      query: () => ({
        url: `/emergency/freeze/disable`,
        method: "POST",
      }),
    }),
    getEmergencyStatus: builder.mutation<any, any>({
      query: () => ({
        url: `/emergency/status`,
        method: "GET",
      }),
    }),
    emergencyDeleteRequest: builder.mutation<
      any,
      {
        verificationCodes?: Record<any, any>;
        withoutBackup?: boolean;
      }
    >({
      query: (data) => ({
        url: `/emergency/delete/request`,
        method: "POST",
        body: data,
      }),
    }),
    emergencyDeleteConfirm: builder.mutation<
      any,
      {
        verificationCodes?: Record<any, any>;
        withoutBackup?: boolean;
      }
    >({
      query: (data) => ({
        url: `/emergency/delete/confirm`,
        method: "POST",
        body: data,
      }),
    }),
    emergencyBackupRequest: builder.mutation<
      any,
      {
        verificationCodes?: Record<any, any>;
      }
    >({
      query: (data) => ({
        url: `/emergency/backup/request`,
        method: "POST",
        body: data,
      }),
    }),
    emergencyBackupDownload: builder.mutation<
      any,
      {
        verificationCodes?: Record<any, any>;
      }
    >({
      query: (data) => ({
        url: `/emergency/backup/download`,
        method: "POST",
        body: data,
      }),
    }),
    emergencyBackupGenerate: builder.mutation<any, any>({
      query: () => ({
        url: `/emergency/backup/generate`,
        method: "POST",
      }),
    }), //TODO: for testing
    emergencyClearAll: builder.mutation<any, any>({
      query: () => ({
        url: `/emergency/clear`,
        method: "POST",
      }),
    }), //TODO: for testing
    emergencyDeleteApprove: builder.mutation<
      any,
      {
        verificationCodes?: Record<any, any>;
      }
    >({
      query: (data) => ({
        url: `/emergency/delete/approve`,
        method: "POST",
        body: data,
      }),
    }),
    getClients: builder.mutation<
      any,
      { searchParamsString: string; page: number; perPage: number }
    >({
      query: ({ searchParamsString, page, perPage }) => ({
        url: `${clients}${searchParamsString ? `?${searchParamsString}` : ""}`,
        method: "GET",
        params: { page, perPage },
      }),
    }),
    getClientDocumentsById: builder.mutation<any, { uuid: string }>({
      query: ({ uuid }) => ({
        url: `${client}/${uuid}/documents`,
        method: "GET",
      }),
    }),
    getClientDocumentById: builder.mutation<
      any,
      { clientUuid: string; documentUuid: string }
    >({
      query: ({ clientUuid, documentUuid }) => ({
        url: `${client}/${clientUuid}/document/${documentUuid}`,
        method: "GET",
      }),
    }),
    getClientById: builder.mutation<any, { uuid: string }>({
      query: ({ uuid }) => ({
        url: `${client}/${uuid}`,
        method: "GET",
      }),
    }),
    updateClientProfile: builder.mutation<
      any,
      { uuid: string; data: FieldValues }
    >({
      query: ({ uuid, data }) => ({
        url: `${client}/${uuid}`,
        method: "PUT",
        body: data,
      }),
    }),
    createClientProfile: builder.mutation<any, { data: FieldValues }>({
      query: ({ data }) => ({
        url: `${client}`,
        method: "POST",
        body: data,
      }),
    }),
    deleteRelationship: builder.mutation<
      any,
      { uuid: string; relationshipUuid: string }
    >({
      query: ({ uuid, relationshipUuid }) => ({
        url: `${client}/${uuid}/relationship/${relationshipUuid}`,
        method: "DELETE",
      }),
    }),
  }),
});

export default clientApi;

export const {
  getClients,
  sendCode,
  verifyCode,
  getAuthInfo,
  deleteAuth,
  changePassword,
  changePasswordConfirm,
  freezeAccount,
  unfreezeAccount,
  getEmergencyStatus,
  emergencyDeleteRequest,
  emergencyDeleteConfirm,
  emergencyBackupRequest,
  emergencyBackupDownload,
  emergencyBackupGenerate, //TODO: for testing
  emergencyClearAll, //TODO: for testing
  emergencyDeleteApprove,
  signInWithSubdomain,
  getClientDocumentsById,
  getClientById,
  deleteRelationship,
  getClientDocumentById,
  updateClientProfile,
  createClientProfile,
} = clientApi.endpoints;
