import { FC } from "react";

import { Box } from "@mui/material";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";

import { ClientInfo } from "../ClientInfo/ClientInfo";
import { NotificationComponent } from "../NotificationComponent";
import { ClientDocuments } from "../ClientDocuments/ClientDocuments";
import { HomeComponent } from "../pages/HomeComponent/HomeComponent";
import { SigninComponent } from "../SigninComponent/SigninComponent";
import { SignupComponent } from "../SignupComponent/SignupComponent";
import { AuthComponent } from "../pages/AuthComponent/AuthComponent";
import { ClientComponent } from "components/ClientComponent/ClientComponent";
import { ClientsComponent } from "../pages/ClientsComponent/ClientsComponent";
import { AccountComponent } from "../pages/AccountComponent/AccountComponent";
import { CompanyComponent } from "../pages/CompanyComponent/CompanyComponent";
import { LocationsComponent } from "../pages/LocationsComponent/LocationsComponent";
import { DocumentsComponent } from "../pages/DocumentsComponent/DocumentsComponent";
import { WorkflowsComponent } from "../pages/WorkflowsComponent/WorkflowsComponent";
import { ResetPasswordComponent } from "../ResetPasswordComponent/ResetPasswordComponent";
import { WorkflowComponent } from "../WorkflowComponent/WorkflowComponent";

import { NewDocumentsComponent } from "../NewDocumentComponent/NewDocumentComponent";
import { Landing, LandingInner } from "../../modules/Landing/Landing";
import PrivacyPolicy from "../../modules/Landing/pages/privacyPolicy";
import CookiesPolicy from "../../modules/Landing/pages/cookiesPolicy";
import DeletionPolicy from "../../modules/Landing/pages/deletionPolicy";
import TermsOfService from "modules/Landing/pages/termsOfService";
import { DropboxConnect } from "../pages/DropboxConnect/DropboxConnect";
import { NewLocationComponent } from "components/NewLocationComponent/NewLocationComponent";
import { AuthGuard } from "./AuthGuard";
import { RedirectToMainDomainGuard } from "./RedirectToMainDomainGuard";

const RoutesWrapper = () => {
  return useRoutes([
    {
      path: "dropbox-connect",
      element: <RedirectToMainDomainGuard element={<DropboxConnect />} />,
    },

    {
      path: "/",
      element: <RedirectToMainDomainGuard element={<Landing />} />,
      children: [
        { index: true, path: "/", element: <LandingInner /> },
        { path: "/privacy-policy", element: <PrivacyPolicy /> },
        { path: "/cookies-policy", element: <CookiesPolicy /> },
        { path: "/deletion-policy", element: <DeletionPolicy /> },
        { path: "/terms-of-service", element: <TermsOfService /> },
      ],
    },

    {
      element: <RedirectToMainDomainGuard element={<AuthComponent />} />,
      children: [
        { path: "signup", element: <SignupComponent /> },
        { path: "signin", element: <SigninComponent /> },
        { path: "reset_password", element: <ResetPasswordComponent /> },
      ],
    },
    {
      element: <AuthGuard element={<CompanyComponent />} />,
      children: [
        { path: "home", element: <AuthGuard element={<HomeComponent />} /> },
        {
          path: "locations",
          element: <AuthGuard element={<LocationsComponent />} />,
        },
        {
          path: "location",
          children: [
            {
              path: "newLocation",
              element: <AuthGuard element={<NewLocationComponent />} />,
            },
            {
              path: ":locationId",
              element: <AuthGuard element={<NewLocationComponent />} />,
            },
          ],
        },
        {
          path: "documents",
          element: <AuthGuard element={<DocumentsComponent />} />,
          children: [{ path: "newDocument" }],
        },
        {
          path: "document",
          children: [
            {
              path: ":documentId",
              element: <AuthGuard element={<NewDocumentsComponent />} />,
            },
          ],
        },
        {
          path: "workflows",
          element: <AuthGuard element={<WorkflowsComponent />} />,
        },
        {
          path: "workflow/:id",
          element: <AuthGuard element={<WorkflowComponent />} />,
        },
        {
          path: "clients",
          element: <AuthGuard element={<ClientsComponent />} />,
          children: [{ path: "clientInfo" }],
        },
        {
          path: "client",
          children: [
            {
              path: "newClient",
              element: <AuthGuard element={<ClientComponent />} />,
              children: [{ path: "info", element: <ClientInfo /> }],
            },
            {
              path: ":clientId",
              element: <AuthGuard element={<ClientComponent />} />,
              children: [
                { path: "info", element: <ClientInfo /> },
                { path: "documents", element: <ClientDocuments /> },
              ],
            },
          ],
        },
        {
          path: "account",
          element: <AuthGuard element={<AccountComponent />} />,
        },
      ],
    },
  ]);
};

export const AppWrapper: FC = () => {
  return (
    <Box sx={{ height: "100%", maxHeight: "100%", overflow: "auto" }}>
      <Router>
        <NotificationComponent />
        <RoutesWrapper />
      </Router>
    </Box>
  );
};
